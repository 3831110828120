<nb-card class="small-header smart-table dialog-list">
  <nb-card-header>
    <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : ('In bảng giá' | translate:{definition: this.cms.translateText('Common.list'), action: ''} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
  </nb-card-header>

  <nb-card-body>
    <div class="ng2-smart-table-wrap">
      <input type="file" #uploadBtn ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple style="display: none;">
      <ng2-smart-table #smartTable *ngIf="settings" [attr.select]="hasSelect" #table [settings]="settings" [source]="source" (delete)="delete($event)" (edit)="onEditAction($event)" (create)="onSerialAction($event)" (userRowSelect)="onUserRowSelect($event)" (rowSelect)="onRowSelect($event)">
      </ng2-smart-table>
    </div>
  </nb-card-body>
  <!-- <nb-card-footer>
    <div class="row">
      <ngx-form-group [formGroup]="listControl" name="Limit" label="Common.name" [index]="1" [allowCopy]="false" [required]="false">
        <input type="text" nbInput fullWidth formControlName="Limit" placeholder="{{'Số dòng mỗi trong' | translate | headtitlecase}}">
      </ngx-form-group>
    </div>
  </nb-card-footer> -->
</nb-card>